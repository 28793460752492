$.houdiniforms = {messages : {}};

$.extend( $.houdiniforms.messages, {
	steps_cancel: "Cancel·la",
	steps_current: "Pas actual:",
	steps_pagination: "Paginació",
	steps_finish: "Finalitza",
	steps_next: "Següent",
	steps_previous: "Anterior",
	steps_loading: "Carregant ..."
} );