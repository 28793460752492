var idioma = $('html').attr('lang');
$('.houdiniForm').each(function() {//per cada formulari
    'use strict';

    $(this).find('.protecciodades a').attr('target', '_blank');

    //validacio formulari
    $(this).validate({
        errorElement: 'p',
        errorPlacement: function(error, element) {
          error.addClass("help is-danger");

          if (element.attr('type') === 'checkbox' || element.attr('type') === 'radio') {
                if (element.closest('div').find('input').length > 1) {
                  //error.insertAfter(element.closest('div').find('p.label'));
                  error.insertAfter(element.closest('div').find('ul'));
                } else {
                  error.insertAfter(element.parent());
                }
            } else if (element.is("select")) {
                if (element.closest('div.select').length > 1) {
                  error.insertAfter(element.closest('div.select'));
                } else {
                  error.insertAfter(element.parent());
                }
            } else {
              error.insertAfter(element);
            }
        }
        /*,invalidHandler: function(form, validator) {
          var errors = validator.numberOfInvalids();
          if (errors) {
            console.log('validate');
            console.log($(this));
            if ($(this).find('.form-alert').length == 0) {
              $(this).prepend('<div class="form-alert" role="alert">S’han produït errors. Reviseu el formulari per corregir-los</div>');
            }
          }
        }*/
    });

    $(this).find('option[value="others_field"]').each(function(){
      var select = $(this).closest('select');
      var other = $(this).closest('.control').find('input[type="text"]');
      other.hide();
      select.on('change', function(){
          if (select.val() == 'others_field') {
            other.show();
          } else {
            other.hide().val('');
          }
      });
    });

    $(this).find('input[data-type="others_field"]').each(function(){
      var other = $(this).closest('ul').find('input[type="text"]')
      var inputs = $(this).closest('ul').find('input');
      other.hide();
      inputs.on('change', function(){
          if ($(this).closest('ul').find('input[data-type="others_field"]').is(":checked")){
            other.show();
            other.focus();
          }else{
            other.hide().val('');
          }
      });
    });
});

//datapicker

/*$('.houdiniForm .hasDatepicker').fdatepicker({
    language: idioma,
    format: "dd/mm/yyyy",
    weekStart: 1,
    leftArrow:
    '&lt;',
    rightArrow:
    '&gt;'
});*/

//chosen
$('.houdiniForm .chzn-select').select2();

$.fn.raty.defaults.path = '/media/img';
$.fn.raty.defaults.cancel = false;

//valoracio
$('.houdiniForm .scoreField').each(function() {
    'use strict';
    var field = $(this);
    var id = '#' + field.attr('id');
    var score = $('<div></div>');
    score.raty({
        half: true,
        hints: ['', '', '', '', ''],
        score: function() {
            return field.val();
        },
        targetScore: id
    });
    field.hide();
    field.after(score);
});

//select de dnis
$('.houdiniForm .dninifnie').each(function() {
    'use strict';
    $(this).find('select').on('change', function() {
        var value = $(this).val();
        var input = $(this).closest('div.row').find('input');

        if (value === 'DNI') {
            input.rules('add', {
                dni: true,
                cifES: false,
                nieES: false
            });
        } else if (value === 'CIF') {
            input.rules('add', {
                dni: false,
                cifES: true,
                nieES: false
            });
        } else if (value === 'NIE') {
            input.rules('add', {
                dni: false,
                cifES: false,
                nieES: true
            });
        }
    });
});

//other option for selects, checkbox and radio
$('div.other input').each(function() {
  $(this).prop( "disabled", true );
});
$('.select select').change(function(){
    var value = $(this).val();
    var other =  $(this).parent().parent().find('div.other');
    var inputOther = other.children("input");
    if(value == 'others_field'){
        other.removeAttr('hidden');
        inputOther.prop( "disabled", false );
    }else{
        other.attr('hidden', 'true');
        inputOther.prop( "disabled", true );
    }
});
$('.field input[type="radio"]').change(function(){
    var value = $(this).data("type");
    var other =  $(this).parent().parent().find('div.other');
    var inputOther = other.children("input");
    if(value == 'others_field'){
        other.removeAttr('hidden');
        inputOther.prop( "disabled", false );
    }else{
        other.attr('hidden', 'true');
        inputOther.prop( "disabled", true );
    }
});
$('.field input[type="checkbox"]').change(function(){
    var value = $(this).data("type");
    var other =  $(this).parent().parent().find('div.other');
    var inputOther = other.children("input");
    if(value == 'others_field'){
        if($(this).is(':checked')){
           other.removeAttr('hidden');
            inputOther.prop( "disabled", false );
        }else{
            other.attr('hidden', 'true');
            inputOther.prop( "disabled", true );
        }

    }
});

//input file bulma css
$('.field input[type="file"]').change(function(e){
    var fileName = e.target.files[0].name;
    $(this).parent().find('.file-name').html(fileName);
});
